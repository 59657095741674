.menucontainer {
    @apply bg-purple-darkest;
    @apply font-light;
    @apply mb-6;
    font-size: 0.6rem;
    @apply antialiased;
}

@screen md {
    .menucontainer {
        @apply text-sm;
    }
}

.menucontainer ul {
    @apply flex;
    @apply justify-between;
    @apply list-reset;
    @apply py-2;
}

.nav__item a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    @apply text-white;
}

/* @screen md {
    .nav__item a {
        display: inline-flex;
    }
} */

.nav__item a:hover,
.nav__item.nav-active a {
    @apply text-green-light;
}

.nav__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.4rem;
}

@screen md {
    .nav__icon {
        width: 2rem;
        height: 2rem;
    }
}