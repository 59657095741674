.popup {
    position: absolute;
    display: none;
    padding: 20px;
    top: 50%;
    left: 10px;
    right: 10px;
    z-index: 1002;
    @apply bg-green;
    @apply rounded;
    @apply antialiased;
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
}

.popup .button,
.popup button,
.popup input[type="submit"],
.popup input[type="reset"],
.popup input[type="button"] {
    background-color: #3a3557;
}

.popup .button:hover,
.popup button:hover,
.popup input[type="submit"]:hover,
.popup input[type="reset"]:hover,
.popup input[type="button"]:hover {
  background-color: #1b0933;
  cursor: pointer;
}

.fond {
    position: fixed;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    background-color: #000;
    filter: alpha(opacity=50);
    opacity: .5;
    z-index: 1000;
}

/* .popup__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -999;
} */

.fond--vis,
.popup--vis {
    display: block;
}

.load{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
    width:1.5rem;
    height:1.5rem;
  }
  .load hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}
  
  .load :first-child{background:#00A780;animation-delay:-1.5s}
  .load :nth-child(2){background:#ffce00;animation-delay:-1s}
  .load :nth-child(3){background:#ff733f;animation-delay:-0.5s}
  .load :last-child{background:#3a3557}
  
  @keyframes spin{
    0%,100%{transform:translate(0)}
    25%{transform:translate(160%)}
    50%{transform:translate(160%, 160%)}
    75%{transform:translate(0, 160%)}
  }