.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  @apply antialiased;
  @apply rounded;
  border-style: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  background-color: #01d0a2;
  color: #fff;
  -webkit-transition: background-color 400ms;
  transition: background-color 400ms;
  text-decoration: none;
  font-weight: 100;
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
  background-color: #00a780;
  cursor: pointer;
}

.button--yellow-dark
input[type=button].button--yellow-dark,
input[type=reset].button--yellow-dark,
input[type=submit].button--yellow-dark {
  @apply bg-yellow-dark;
}

.button--yellow-dark:hover
input[type=button].button--yellow-dark:hover,
input[type=reset].button--yellow-dark:hover,
input[type=submit].button--yellow-dark:hover {
  @apply bg-yellow-darkest;
}

a.button:hover {
  color: #fff;
}

.icon-button {
  text-align: left;
}

button img,
.button img {
  /* width: 15px; */
  height: 68%;
  margin-right: 0.5rem;
  vertical-align: middle;
} 

@screen md {
  button img,
  .button img {
    margin-right: 1rem;
  } 
}

.btn {
  @apply mb-2;
}

.btn--full {
  width: 100%;
}

.btn--outline {
  @apply .border-grey;
}

.btn--outline:hover {
  @apply .bg-green-dark;
  @apply .border-green-dark;
}

/* over-rides for symphony styles */
.e-grid .e-unboundcelldiv button {
  padding: .1em .4em .3em;
}

.balancesBTN {
  margin: 0.5em 0;
}

/* 
button.box-btn,
input[type="submit"].box-btn,
input[type="reset"].box-btn,
input[type="button"].box-btn {
  width: 100px;
  margin-top: 20px;
  margin-left: 10px;
  float: right;
} */

/* .e-pager .e-currentitem {
    background: rgba(57, 148, 209, 0.5) !important;
  }
  
  .e-grid td.e-active {
    background: rgba(57, 148, 209, 0.5) !important;
  }
  
  .e-hover {
    background: rgba(57, 148, 209, 0.3) !important;
  }
  
  .e-pager .e-currentitem {
    background: rgba(57, 148, 209, 0.5) !important;
    color: #333 !important;
  }
  
  .passBoxBut {
    background-color: #01d0a2 !important;
  }
  
  .passBoxBut:hover {
    background-color: #00a780 !important;
  }
  
  .e-btn:hover {
    background: rgba(27, 64, 121, 0.5) !important;
    color: #fff !important;
  } */