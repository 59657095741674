/* specificity seems to be required */

.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_container{
    padding: 1rem;
    border: 1px solid #a5a2b2;
    border-radius: 4px;
    @apply font-sans;
    @apply text-xs;
    width: auto;
    color: #1b0933;
}

.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_dayname {
    height: 20px;
    width: 20px;
    text-align: center;
    padding: 0 2px;
}

.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_other .ajax__calendar_day {
    @apply text-grey;
}

.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_today .ajax__calendar_day,
.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_active .ajax__calendar_day {
    background-color: #01d0a2;
    border-color: #01d0a2;
    color: #ffffff;
    border-radius: 50%;
}

.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_hover .ajax__calendar_day {
    background-color: #88ead4;
    border-color: #88ead4;
    color: #ffffff;
    border-radius: 50%;
}

.ajax__calendar.ajax__calendar--cp-cal .ajax__calendar_day {
    width: 22px;
    height: 22px;
    text-align: center;
    padding: 3px 2px 2px;
}