input,
textarea,
select,
fieldset {
  @apply mb-6;
  @apply w-full;
}

label,
.label {
    @apply block text-sm font-bold mb-2;
}

input,
select {
    @apply appearance-none border rounded w-full py-2 px-3 leading-tight;
}

input.error {
  @apply border-red;
}

.custom-select {
  @apply relative;
  @apply text-black;
  @apply w-full;
}

.custom-select select {
 @apply block px-3 pr-8 rounded leading-tight;
}

.custom-select .caret {
  @apply pointer-events-none absolute pin-y pin-r flex items-center px-2;
}

.e-btn.e-select.e-deletebutton {
  @apply bg-red;
  color: white;
}

/* SPECIAL CASE */

.input--reason-for-transfer .ajax__dropdown_frame_line,
.input--reason-for-transfer .ajax__dropdown_arrow {
  display: none;
}