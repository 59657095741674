th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #E1E1E1; }
/* th:first-child,
td:first-child {
  padding-left: 0; }
th:last-child,
td:last-child {
  padding-right: 0; } */

.printTable td,
.printTable th {
  padding: 5px;
}

/*
table>* {
	display: block;
	overflow-y: auto;
	max-height: 800px;
}
*/
/* table td,
table th {
  padding: 1px 5px;
}

.numeric {
  text-align: right;
}

table.figures { */
  /* width: 100%; */
  /* font-size: 14px;
  background-color: #fff;
  color: #1b0933;
  border: 1px solid #22292f;
  border-collapse: separate;
  border-left: 0;
  border-spacing: 0;
  border-radius: 5px;
}

table.figures thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 5px 0 0 0;
}

table.figures thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

table.figures tbody tr:nth-child(even) {
  background-color: #f3f3f3;
  color: #1b0933;
}

table.figures td,
table.figures th {
  border-left: 1px solid #22292f;
  padding: 10px 5px;
  text-align: center;
}

table.figures td {
  border-top: 1px solid #22292f;
}

td.rowlabel {
  font-weight: 600;
}

td.actionicon {
  width: 80px;
}

td.actionicon img {
  width: 25px;
  height: 25px;
}

td img {
  vertical-align: middle;
} */