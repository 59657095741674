html,
body {
  height: 100%;
  background-color: #3a3557;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* #form1 {
  flex: 1 0 auto;
} */

/* body > form {
  height: 100%;
  margin: 0;
} */

/* .table-container {
  display: table;
  height: 100%;
  width: 100%;
  background-color: #3a3557;
}

.table-block {
  display: table-row;
  height: 1px;
}

.footer-push {
  height: auto;
}

.banner-row {
  margin: 20px;
} */



/* .banner-row figure {
  margin: 0;
} */

/* .menucontainer {
  position: relative;
  margin: 25px 0;
} */

/* #header {
  z-index: 2;
  width: 100%;
} */



@media (max-width: 1219px) {
  /* #header .menucontainer {
    width: 100%;
  } */

  /* #header h1 {
    left: 10px;
  } */

  /* #nav {
    width: 100%;
  }

  #nav ul li {
    width: 14%;
    text-align: center;
    margin: 0;
  }

  #nav ul li a span {
    display: none;
  } */

  /* .column,
  .columns {
    margin-left: 3px;
    width: 98% !important;
  }

  .mobileMarginRates {
    margin-top: 20px;
  }

  .row {
    margin-left: 0px !important;
  } */
}
/* 
a {
  color: #1b4078;
  text-decoration: none;
}

hr {
  margin: 5px;
  height: 1px;
  background-color: #8835d4;
}

.big-centred-a {
  margin-top: 10px;
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.large-text {
  font-size: 20px;
}

.footnote {
  font-size: small;
}

.col-theme-1 {
  color: #fff;
}

.col-theme-2 {
  color: #3994d1;
}

.footnote-1 li,
.footnote-2 li {
  margin: 0;
} */

/* div#pin-digits {
  margin: 20px;
  text-align: center;
  font-size: large;
}

div#pin-digits input {
  font-family: monospace;
  text-align: center;
  width: 30px;
}

div#pin-digits input:disabled {
  background: #888;
} */

/* h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
} */

/* strong { */
  /* color:#1b4078; */
  /* font-size: 16px;
  font-weight: 400px;
}

div.padded-row {
  margin-top: 50px;
}

div.padded {
  padding: 10px;
}

.hrule {
  border-top: 1px solid #22292f;
}

.centred {
  text-align: center;
  margin: 0 auto;
  display: table;
}

.vertically-centred {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
} */



/*
input[type="text"], input[type="email"], input[type="tel"], select {
	width: 100px;
}
*/
/* input.narrow-input,
select.narrow-input {
  width: 100px;
}

input.wide-input,
select.wide-input {
  width: 210px;
} */





/* label {
  color: #22292f;
  font-size: 12.5px;
  font-weight: 700;
} */



/* JT: debug show grid borders 
div.columns, div.column {
	border: 1px solid green;
	border-style: dashed;
}
*/


/* .banner-row figure img#mercury-logo {
  width: 15%;
} */

/* .banner-row img#map-logo {
  position: absolute;
  top: 18%;
  right: 2%;
  width: 10%;
}

.banner-row img#map-logo-other {
  position: absolute;
  top: 35%;
  right: 2%;
  width: 10%;
} */

/* table {
  margin-bottom: 0 !important;
}

#cmdCSVdownload,
#cmdPDFDownload,
#cmdPrint {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

#txtDateFrom,
#txtDateTo,
#ddlCCY,
#ddlBeneficiary {
  margin-bottom: 20px !important;
  font-family: "Open Sans";
  font-size: 12px;
} */



/* .u-full-width {
  margin-top: 20px;
} */

/* #txtDateTo {
  margin-right: 6px;
}

#cboInstruction,
#txtAmount,
#txtReference {
  margin-top: 15px;
}

#cmdValidateTrade {
  height: 30px !important;
}

.quickTradeDone {
  padding-top: 5px;
  height: 25px !important;
  overflow: hidden;
}

.e-grid td.e-active {
  color: #333 !important;
}

#cmdView {
  margin-top: 10px;
}

a:hover {
  text-decoration: none !important;
} */



/* @media (max-width: 990px) {
  .banner-row {
    margin: 10px 0px 0px 0px !important;
  }

  .banner-row img#map-logo-other {
    top: 50% !important;
  }

  .banner-row img#map-logo {
    top: 30% !important;
  }
}

@media (max-width: 376px) {
  .instructions {
    width: 375px !important;
  }

  .account-mobile {
    margin-top: 20px !important;
  }
} */

