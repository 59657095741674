.box {
  border: none;
  border-radius: 5px;
  background-color: #fff;
  color: #22292f;
  @apply mb-6;
}

.box-light {
  border: 1px solid #3994d1;
  border-radius: 5px;
  padding: 5px;
}

.box-heading-panel {
  @apply p-6;
  border-bottom: 1px solid #22292f;
}

.box-heading {
  color: #22292f;
  font-weight: 700;
}

.box-body-panel {
  @apply p-6;
}

.box-foot-panel {
  @apply px-6;
}

.box.box--alert {
  @apply bg-green-lightest;
}

.box--alert .box-heading-panel {
  @apply border-green;
}