.html,
body {
  @apply .font-sans;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  @apply mb-4;
  @apply font-normal; }
h1 { line-height: 1.2;  }
h2 { line-height: 1.25; }
h3 { line-height: 1.3;  }
h4 { line-height: 1.35; }
h5 { line-height: 1.5;  }
h6 { line-height: 1.6;  }

p {
  @apply mb-4;
}

#tagline {
  text-transform: lowercase;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin-left: 53px;
  margin-top: -12px;
}

.error {
  @apply text-red;
}

/* Check how better to target generated tags */
/* #lblErrorMessage {
  display: inline-block;
  @apply mb-4;
} */

span#lblClientRate {
  padding: 0.5em;
  @apply rounded;
}

.e-input, .e-tooltipbox, .e-btn, .e-time-popup, .e-datepicker.e-calendar, .e-menu, .e-uploadbox .e-file-name-txt, .e-uploadbox .e-selectpart, .e-uploadbox .e-uploadbtn, .e-uploadbox .e-uploadclosebtn, .e-waitpopup-pane .e-text, .e-tagcloud, .e-toolbar, .e-progressbar, .e-scale .e-tick .tick-value, .e-datetime-popup .e-dt-button, .e-grid, .e-ejinputtext, .e-pager, .e-fileexplorer, .e-textbox, .e-textarea {
  @apply font-sans;
}